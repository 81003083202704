import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseTemplate, PageHead } from '@myob/myob-widgets';
import { ContactDetailsWrapper } from './styles';
import { ContactForm } from './components/ContactForm';
import { GetContactDetailsState, fetchContactDetailsAsync } from './reducers/getContact';
import { updateContactDetailsAsync, UpdateContactDetailsState, resetIsUpdateError } from './reducers/updateContact';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import LoadingWrapper from 'components/LoadingWrapper';
import { EPage } from 'telemetry/type';
import ErrorComponent from 'components/ErrorComponent';
import ModalBox from '../../components/ModalComponent';
import { Notification } from '../../components/Notification';
import { isEmpty, omitBy } from 'lodash';
import { ContactDetailsInfo, EmailDetailsInfo } from './type';
import { UpdateContactDetailsSchema } from 'helpers/api';
import { ENotificationType } from 'stores/reducers/notification/type';
import { identityTrack } from '../../helpers/tools';
import { productFeatureFlag } from '../../configs/FeatureFlags';
import { useAccountSelectorState } from '@my-account/account';
import NewContactForm from './components/ContactForm/NewContactForm';
import EmailForm from './components/EmailForm/EmailForm';
import { resetIsUpdateEmailError, updateEmailDetailsAsync, UpdateEmailDetailsState } from './reducers/updateEmail';
import { HttpStatus } from '../../helpers/request';
import { auth } from '@my-account/tools';

const ContactDetails: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const formRef = useRef(null);
  const emailFormRef = useRef(null);

  const {
    data: contactInfo,
    isLoading,
    isError,
  } = useSelector<RootState, GetContactDetailsState>((state) => state.getContact);

  const { isError: isUpdateError } = useSelector<RootState, UpdateContactDetailsState>((state) => state.updateContact);
  const [{ isSoloAccount }] = useAccountSelectorState();

  const [showModal, setShowModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const { isError: isUpdateEmailError, error: updateEmailError } = useSelector<RootState, UpdateEmailDetailsState>(
    (state) => state.updateEmail,
  );

  const statusErrorToMessage = (status: HttpStatus) => {
    if (status === HttpStatus.Conflict) {
      return 'This email is used with another MYOB account. Use a different email address.';
    } else {
      return 'Unable to update email. Try again.';
    }
  };

  useEffect(() => {
    dispatch(resetIsUpdateError());
    dispatch(fetchContactDetailsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdateError === false) {
      dispatch(fetchContactDetailsAsync()).then((res: Record<string, any>) => {
        const contactInfo = {
          firstName: res.payload.firstname,
          lastName: res.payload.lastname,
          emailAddress: res.payload.email,
        };
        identityTrack(contactInfo);
      });
      dispatch({
        type: ENotificationType.Success,
        primaryMessage: '<b>Congratulations.</b><p>Your changes have been saved successfully.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else if (isUpdateError === true) {
      dispatch({
        type: ENotificationType.Danger,
        primaryMessage: '<b>Unfortunately we have encountered a problem.</b><p>Please try again later.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else {
      dispatch({
        type: ENotificationType.Clear,
      });
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isUpdateEmailError === true) {
      emailFormRef.current?.setErrorDetail({
        isError: true,
        errorMessage: statusErrorToMessage(updateEmailError.status),
      });
    } else if (isUpdateEmailError === false) {
      auth.logout();
    } else {
      emailFormRef.current?.setErrorDetail({
        isError: false,
        errorMessage: '',
      });
    }
  }, [isUpdateEmailError, updateEmailError]);

  const handleOnDiscard = () => {
    dispatch(resetIsUpdateError());
    setShowModal(false);
    formRef.current.setIsEdit(false);
    dispatch(fetchContactDetailsAsync());
  };

  const handleOnUpdateEmail = (emailFormData: EmailDetailsInfo) => {
    const data = { newEmail: emailFormData.newEmail };

    dispatch(resetIsUpdateEmailError());
    dispatch(updateEmailDetailsAsync(data));
  };

  const handleGoBack = () => {
    setShowModal(false);
  };

  const handleUpdateEmailCancel = () => {
    setShowUpdateEmailModal(false);
  };
  const updateContactDetails = (formData: ContactDetailsInfo) => {
    const data = { firstname: formData.firstname, lastname: formData.lastname, phone: formData.phone };
    const requestData = omitBy(data, isEmpty) as UpdateContactDetailsSchema;

    dispatch(resetIsUpdateError());
    dispatch(updateContactDetailsAsync(requestData));
  };

  const handleOnDismiss = () => {
    dispatch(resetIsUpdateError());
  };

  const content = useMemo(() => {
    if (isError) {
      return <ErrorComponent pageTitle="Contact details" pageName={EPage.ContactDetails} />;
    }
    return (
      <ContactDetailsWrapper>
        <PageHead title="Contact details" className="page-head" />
        <Notification onDismiss={handleOnDismiss} />
        {contactInfo && (
          <ContactForm
            data-testid="contact-details-form"
            contactInfo={contactInfo}
            onCancel={() => setShowModal(true)}
            ref={formRef}
            onSave={updateContactDetails}
          />
        )}
      </ContactDetailsWrapper>
    );
  }, [isError, contactInfo]);

  const contentWithUpdateEmail = useMemo(() => {
    if (isError) {
      return <ErrorComponent pageTitle="Contact details" pageName={EPage.ContactDetails} />;
    }
    return (
      <ContactDetailsWrapper>
        <PageHead title="Contact details" className="page-head" />
        <Notification onDismiss={handleOnDismiss} />
        {contactInfo && (
          <NewContactForm
            data-testid="contact-details-form"
            contactInfo={contactInfo}
            onCancel={() => setShowModal(true)}
            ref={formRef}
            onSave={updateContactDetails}
            onUpdateEmail={() => setShowUpdateEmailModal(true)}
          />
        )}
      </ContactDetailsWrapper>
    );
  }, [isError, contactInfo]);

  const isUpdateEmailEnable = () => {
    return (
      (productFeatureFlag.isNonSoloUpdatedEmailFeatureEnabled() && !isSoloAccount) ||
      (productFeatureFlag.isSoloUpdatedEmailFeatureEnabled() && isSoloAccount)
    );
  };

  return (
    <div data-testid="contact-details-page">
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Discard unsaved changes?"
          message="You've made changes that will be lost if you don't go back and save them."
        />
      )}
      {showUpdateEmailModal && (
        <EmailForm
          onUpdateEmail={handleOnUpdateEmail}
          onCancel={handleUpdateEmailCancel}
          title="Update account email"
          currentEmail={contactInfo.email}
          ref={emailFormRef}
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <BaseTemplate>{isUpdateEmailEnable() ? contentWithUpdateEmail : content}</BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};

export default ContactDetails;
