import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseTemplate, useToast } from '@myob/myob-widgets';
import LoadingWrapper from '../../components/LoadingWrapper';
import { CommunicationPreferencesDetailsWrapper } from './styles';
import { CommunicationPreferencesForm } from './components/CommunicationPreferencesForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '../../stores';
import {
  fetchCommunicationPreferencesDetailsAsync,
  GetCommunicationPreferencesDetailsState,
} from './reducers/getCommunicationPreferences';
import ModalBox from '../../components/ModalComponent';

import {
  resetIsUpdateError,
  updateCommunicationPreferencesDetailsAsync,
  UpdateCommunicationPreferencesDetailsState,
} from './reducers/updateCommunicationPreferences';
import { CommunicationPreferencesDetailsInfo } from './type';
import { ENotificationType } from '../../stores/reducers/notification/type';
import { EPage } from '../../telemetry/type';
import ErrorComponent from '../../components/ErrorComponent';

const CommunicationPreferencesDetails: React.FC = () => {
  const formRef = useRef(null);
  const dispatch: AppThunkDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { email } = JSON.parse(localStorage.getItem('USER_PROFILE'));
  const {
    data: communicationPreferencesDetailsInfo,
    isLoading,
    isError,
  } = useSelector<RootState, GetCommunicationPreferencesDetailsState>((state) => state.getCommunicationPreferences);

  const { isError: isUpdateError } = useSelector<RootState, UpdateCommunicationPreferencesDetailsState>(
    (state) => state.updateCommunicationPreferences,
  );

  useEffect(() => {
    dispatch(resetIsUpdateError());
    dispatch(fetchCommunicationPreferencesDetailsAsync(email));
  }, []);

  useEffect(() => {
    if (isUpdateError === false) {
      dispatch(fetchCommunicationPreferencesDetailsAsync(email)).finally(() => {
        toast({
          close: { duration: 3000 },
          message: 'Preferences updated',
          tone: 'success',
          onClose: handleOnClose,
        });
      });
    } else if (isUpdateError === true) {
      dispatch({
        type: ENotificationType.Danger,
        primaryMessage: '<b>Something went wrong, please try again</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else {
      dispatch({
        type: ENotificationType.Clear,
      });
    }
  }, [isUpdateError]);

  const updateModalStatus = (status: boolean) => {
    setShowModal(status);
  };

  const handleOnClose = () => {
    dispatch(resetIsUpdateError());
  };

  const handleOnDiscard = () => {
    updateModalStatus(false);
    formRef.current.setIsEdit(false);
    dispatch(fetchCommunicationPreferencesDetailsAsync(email));
  };

  const handleGoBack = () => {
    updateModalStatus(false);
  };

  const updateCommunicationPreferencesDetails = (formData: CommunicationPreferencesDetailsInfo) => {
    dispatch(resetIsUpdateError());
    dispatch(updateCommunicationPreferencesDetailsAsync({ email: email, body: formData }));
  };

  const toast = useToast();

  const content = useMemo(() => {
    if (isError) {
      return <ErrorComponent pageTitle="Communication preferences" pageName={EPage.CommunicationPreferencesDetails} />;
    }
    return (
      communicationPreferencesDetailsInfo && (
        <CommunicationPreferencesDetailsWrapper>
          <CommunicationPreferencesForm
            communicationPreferencesDetails={communicationPreferencesDetailsInfo}
            onSave={updateCommunicationPreferencesDetails}
            onCancel={() => updateModalStatus(true)}
            ref={formRef}
            onDismiss={handleOnClose}
          />
        </CommunicationPreferencesDetailsWrapper>
      )
    );
  }, [communicationPreferencesDetailsInfo, isError]);
  return (
    <div data-testid="communication-preferences-details-page">
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Discard changes?"
          message="Your preferences will not be updated"
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <BaseTemplate>{content}</BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};

export default CommunicationPreferencesDetails;
