import { AppConfig, RegionConfig } from './type';

export const auConfig: RegionConfig = {
  HELP_LINK: 'https://www.myob.com/au/support/contact-us',
  BN_LABEL: 'ABN',
  SIGN_IN_MY_MYOB: 'https://my.myob.com.au/Bd/pages/ChangeLogin.aspx',
  PRIVACY_DISCLOSURE_LINK: 'https://www.myob.com/au/legal/privacy-disclosure-statement',
};

export const nzConfig: RegionConfig = {
  HELP_LINK: 'https://www.myob.com/nz/support/contact-us',
  BN_LABEL: 'NZBN',
  SIGN_IN_MY_MYOB: 'https://my.myob.co.nz/Bd/pages/ChangeLogin.aspx',
  PRIVACY_DISCLOSURE_LINK: 'https://www.myob.com/nz/legal/privacy-disclosure-statement',
};

const config: AppConfig = {
  API_BASE_URL: 'https://account-details.sit.myaccount.myob.com',
};

export default config;
