import { AccountDetailsInfo, BusinessOrBillingAddress } from 'modules/AccountDetails/type';
import { ContactDetailsInfo } from 'modules/ContactDetails/type';
import { request } from 'helpers/request';
import { Account } from '@my-account/account';
import { CommunicationPreferencesDetailsInfo } from '../modules/CommunicationPreferencesDetails/type';

export type UpdateAccountDetails = {
  id: string;
  body: {
    addresses: BusinessOrBillingAddress[];
  };
};

export type UpdateContactDetailsSchema = {
  firstname: string;
  lastname: string;
  phone: string;
};

export type UpdateCommunicationPreferencesDetails = {
  email: string;
  body: CommunicationPreferencesDetailsInfo;
};

export type UpdateEmailDetailsSchema = {
  newEmail: string;
};

export const fetchAccountDetailsByAccountId = async (accountId: Account['clientId']): Promise<AccountDetailsInfo> => {
  return request<AccountDetailsInfo>(`/api/accounts/${accountId}`);
};

export const fetchContactDetails = async (): Promise<ContactDetailsInfo> => {
  return request<ContactDetailsInfo>(`/api/contacts`);
};

export const updateAccountDetailsByAccountUUID = async (data: UpdateAccountDetails): Promise<Response> => {
  const { id, body } = data;

  const config: RequestInit = {
    body: JSON.stringify(body),
    method: 'PUT',
  };

  return request<Response>(`/api/accounts/${id}`, config);
};

export const updateContactDetails = async (data: UpdateContactDetailsSchema): Promise<Response> => {
  const config: RequestInit = {
    body: JSON.stringify(data),
    method: 'PUT',
  };

  return request<Response>(`/api/contacts`, config);
};

export const fetchCommunicationPreferencesDetails = async (
  email: string,
): Promise<CommunicationPreferencesDetailsInfo> => {
  return request<CommunicationPreferencesDetailsInfo>(`/api/communication-preferences/${email}`);
};

export const updateCommunicationPreferencesDetails = async (
  data: UpdateCommunicationPreferencesDetails,
): Promise<Response> => {
  const { email, body } = data;
  const config: RequestInit = {
    body: JSON.stringify(body),
    method: 'PUT',
  };
  return request<Response>(`/api/communication-preferences/${email}`, config);
};

export const updateEmailDetails = async (data: UpdateEmailDetailsSchema): Promise<Response> => {
  const config: RequestInit = {
    body: JSON.stringify(data),
    method: 'PUT',
  };

  return request<Response>(`/api/contacts/email`, config);
};
